<div class="fr-grid-row fr-grid-row--gutters">
    <div class="fr-col-12">
        <h1 class="fr-mt-4w fr-h3">{{'ACCUEIL.NO_CO.TITRE_1' | translate}}</h1>
    </div>
    <div class="fr-col-6 fr-mt-2w">
        <label>
            {{'ACCUEIL.NO_CO.ACCES' |translate}}
        </label>
        <br/><br/>
        <label>
            {{'ACCUEIL.NO_CO.LABEL' | translate}}
        </label>
        <ul>
            <li>
                <p>{{'ACCUEIL.NO_CO.P_1' | translate}}</p>
            </li>
            <li>
                <p>{{'ACCUEIL.NO_CO.P_2' | translate}}</p>
            </li>
        </ul>
        <br>
        <label>{{'ACCUEIL.NO_CO.P_3' | translate}}</label>
    </div>
    <div class="fr-col-6 fr-mt-2w">
        <figure class="fr-content-media" role="group" aria-label="Légende de l‘image">
            <div class="fr-content-media__img">
                <img class="fr-responsive-img fr-ratio-16x9" style="text-align: center; width:50%"
                     src="../../../assets/LogoPHC.jpg"
                     alt="image_phc"/>
            </div>
            <figcaption class="fr-content-media__caption" hidden>Légende PHC</figcaption>
        </figure>
    </div>
</div>
